export const environment = {
  production: true,
  environment: 'STAGING',
  firebaseConfig: {
    apiKey: "AIzaSyDMDRkci5PTKLZtClkqG_SdDA4-LeQgb5U",
    authDomain: "sharescapeio-staging.firebaseapp.com",
    databaseURL: "https://sharescapeio-staging.firebaseio.com/",
    projectId: "sharescapeio-staging",
    storageBucket: "sharescapeio-staging.appspot.com",
    messagingSenderId: "928313811583",
    appId: "1:928313811583:web:78a7daf56b79a64dc9554a",
    measurementId: "G-0EBN194F0W"
  },
  hasura: {
    https: 'https://staging-api.sharescape.io/v1/graphql',
    wss: 'wss://staging-api.sharescape.io/v1/graphql',
  },
  apiUrl: 'https://staging-api.sharescape.io',
  logToConsole: false,
};
